@import '../../../../assets/styles/_variables.scss';
@import '../../../../assets/styles/_mixns.scss';

.navigationButtons {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  justify-content: center;

  .backBtn {
    background: transparent;
    border: none;
    padding: 6px;
    border-radius: 8px;

    &:hover {
      background: $color-gray-silver-light;
    }
  }
}
