@import '../../../assets/styles/_mixns.scss';

.progressBarContainer {
  width: 100%;
  height: 4px;
  background: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 16px;
}

.progressBar {
  height: 100%;
  background: $color-blue-dark;
  transition: width 0.3s ease-in-out;
}
