@import '../../../../assets/styles/_variables.scss';
@import '../../../../assets/styles/_mixns.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 263.69px);

  @include gammaSize {
    align-items: flex-start;
  }
}

.card {
  width: 500px;
  margin: 5% auto;
  padding: 24px;
  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  text-align: center;
  transition: opacity 0.3s ease-in-out;
  min-height: 700px;
  display: flex;
  flex-direction: column;

  @include gammaSize {
    min-height: calc(100vh - 80.69px);
    width: 100vw;
    margin: 0;
    margin-bottom: 40px;
    border-radius: 0px;
    box-shadow: none;
  }
}

.subTitle {
  margin-top: 10px;
}

.question {
  margin-top: 10px;
  margin-bottom: -15px;
}

.questionContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 40px;
}

.topIconImage {
  width: 50px;
}

.iconCircle {
  width: 96px;
  height: 96px;
  background-color: #e3f2fd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 24px;
  color: #1976d2;
}
