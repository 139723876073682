@import '../../../assets/styles/variables';

.focusAreaItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2px 0;
  border-radius: 25px;
  cursor: pointer;
  border: 3px solid transparent;
  background-color: $white;
  transition: background-color 0.3s, border-color 0.3s;
  width: 100%;
  height: 46px;
  transition: transform 0.3s ease-in-out;

  &.selected {
    background-color: $white;
  }

  .leftCircle {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    color: $white;
    border: 2px solid $white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 24px;
    margin-right: 15px;
    margin-left: -3px;
  }

  .itemName {
    flex-grow: 1;
    font-size: 1em;
    color: $text-dark-grey;
  }

  .checkCircle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: $color-sky-blue-dark;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    border: 2px solid transparent;
  }

  .checkmark {
    color: $white;
    font-size: 0.8em;
  }
}

.focusAreaItem:hover {
  transform: translateY(-1px);
}
