@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.tasContainer {
  padding: 20px;
  .actionContainer {
    .coloredBoxContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      .coloredBox {
        width: 100%;

        @include alphaSize {
          width: 100%;
        }
      }

      .footerContainer {
        position: relative;
        overflow: hidden;
        margin-right: -30px;
        margin-bottom: -150px;

        @include alphaSize {
          margin-bottom: -100px !important;
        }
      }

      .footerImage {
        float: right;
        clear: both;
        width: 300px;
        height: 300px;
        background-image: url('../../../../assets/images/score-action.svg');
        background-size: cover;
      }
    }
  }

  .navigateBtnContainer {
    display: flex;
    flex-direction: row;
    gap: 100px;
    width: 100%;
    justify-content: center;
    margin-top: 40px;

    @include gammaSize {
      flex-direction: column;
      gap: 20px;
    }
  }

  .noFocusContainer {
    font-size: 30px;
    display: flex;
    justify-content: center;
    width: 100%;
    color: white;
    flex-direction: column;
    align-items: center;
    height: 80%;

    .blueTray {
      height: 94px;
    }

    .noChosenAreaText {
      font-size: 24px;
      font-weight: 600;
      margin-top: 40px;
      color: white;
      text-align: center;
    }
  }

  .centeredButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .hsNoData {
    font-size: 30px;
    display: flex;
    justify-content: center;
    width: 100%;
    color: white;
  }

  .hsCategoryContainer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    width: 100%;
  }
}
