@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

/* Base button style */
.button {
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  transition: all 0.2s ease-in-out !important;
  cursor: pointer !important;
  border: none !important;
  font-family: inherit !important;
  box-sizing: border-box !important;
}

/* Width variants */
.fitContent {
  width: auto !important;
  max-width: fit-content !important;
  display: inline-flex !important;
}

.fullWidth {
  width: 100% !important;
  display: flex !important;
}

/* Variants */
// primarySkyBlue
.primarySkyBlue {
  background-color: $color-sky-blue-medium !important;
  color: $white !important;
  border-radius: 24px !important;
}

.primarySkyBlue:disabled {
  background: $text-deactivated !important;
}

.primarySkyBlue:hover {
  background-color: $color-sky-blue-light !important;
}

.primarySkyBlue:disabled:hover {
  background: $text-deactivated !important;
}

// outlinedPrimarySkyBlue
.outlinedPrimarySkyBlue {
  background-color: $white !important;
  border: 2px solid $color-sky-blue-medium !important;
  color: $color-sky-blue-medium !important;
  border-radius: 24px !important;
}

.outlinedPrimarySkyBlue:disabled {
  background-color: $white !important;
  border: 2px solid $text-deactivated !important;
  color: $text-deactivated !important;
  border-radius: 24px !important;
}

.outlinedPrimarySkyBlue:hover {
  border: 2px solid $color-sky-blue-light !important;
  color: $color-sky-blue-light !important;
}

.outlinedPrimarySkyBlue:disabled:hover {
  border: 2px solid $text-deactivated !important;
  color: $text-deactivated !important;
}

// primaryDarkSkyBlue
.primaryDarkSkyBlue {
  background-color: $color-sky-blue-dark !important;
  color: $white !important;
  border-radius: 24px !important;
}

/* Sizes */
.small {
  font-size: 14px !important;
  padding: 6px 12px !important;
  font-weight: 600 !important;
  padding: 9px 44px !important;
}

.medium {
  font-size: 16px !important;
  padding: 10px 16px !important;
  font-weight: 600 !important;
  padding: 9px 44px !important;
}

.large {
  font-size: 18px !important;
  padding: 14px 20px !important;
  font-weight: 600 !important;
  padding: 9px 44px !important;
}

/* Disabled state */
.disabled {
  background: $text-deactivated !important;
  opacity: 0.5 !important;
  border: 2px solid $text-deactivated !important;
  cursor: not-allowed !important;
  padding: 9px 44px !important;
}

.disabled:hover {
  opacity: 0.3 !important;
}

/* Loading Spinner */
.loader {
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top: 3px solid white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 0.6s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
