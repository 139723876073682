.imageContainer {
  position: relative;
}

.image {
  max-width: 100%;
  height: auto;
  transition: opacity 0.3s ease;

  &.loading {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  &.circleImage {
    border-radius: 50%;
    object-fit: cover;
  }

  &.roundedImage {
    border-radius: 8px;
    object-fit: cover;
  }
}

.skeleton {
  max-width: 100%;
  height: auto;
  transition: opacity 0.3s ease;

  &.circleSkeleton {
    border-radius: 50%;
  }

  &.roundedSkeleton {
    border-radius: 8px;
  }
}
