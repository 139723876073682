.button {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  &--primary {
    background-color: #2563eb;
    color: white;
  }

  &--secondary {
    background-color: #6b7280;
    color: white;
  }
}
