@import '../../../../../../assets/styles/variables';

.hsMobileToggler {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: $white;
  padding: 26px 30px 14px;
  font-size: 20px;
  font-weight: 600;
  color: $text-dark-grey;
  border-bottom: 4px solid $color-sun-yellow-medium;

  @media screen and (max-width: 768px) {
    padding: 15px;
  }

  i {
    font-size: 44px;
    color: $color-sky-blue-medium;
    transition: all 600ms cubic-bezier(0.62, 0.04, 0.3, 1.56);
  }

  &.open {
    i {
      transform: rotate(180deg);
    }
  }
}
