@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.instantHelpContainer {
  margin-bottom: 100px;

  .instantHelpTopBox {
    @include flexContainer(column, nowrap, space-between, normal, 0);
    background-color: $color-sky-blue-medium;
    height: auto;
    min-height: 300px;
    overflow: hidden;
    padding: 60px 60px 0 60px;
    position: relative;
    z-index: -1;
    width: 100%;
  }

  .content {
    z-index: 99999;
    .titleText {
      @include heading2;
      color: $white;
      margin: 0;
    }

    .middleText {
      @include bodyCopyLarge;
      text-align: justify;
      color: $white;
      margin-top: 24px;
      width: 80%;
    }
  }

  .abstractImg {
    background-image: url('../../../../assets/images/earlyWarning/abstract-v1.svg');
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: right top;
    position: absolute;
    margin-left: 100px;
    bottom: -50px;
    margin-top: 130px;
    z-index: -1;

    @include deltaSize {
      bottom: -110px;
    }
  }

  @include betaSize {
    margin-bottom: 75px;
    .instantHelpTopBox {
      @include flexContainer(column, nowrap, space-between, normal, 0);
      min-height: 260px;
      padding: 20px 10px 0 10px;
      height: auto;

      .content {
        margin-top: 10px;
        .middleText {
          @include bodyCopy;
          color: $white;
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }

    .abstractImg {
      bottom: -140px;
      transform: rotate(-10deg);
    }
  }

  .loadingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 30vh;

    .spinner {
      color: $color-sky-blue-medium;
    }
  }

  .tabSection {
    position: relative;
    z-index: 1;

    @include gammaSize {
      display: none;
    }
  }

  .box {
    background-color: $white;
    position: relative;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    border-radius: 0px 30px 30px 30px;

    z-index: 2;
    .borderBoxContainer {
      padding: 5px 30px 70px 30px;

      @include gammaSize {
        margin: 0px 0px 0px 0px !important;
        padding: 20px 20px 20px 20px;
      }
    }
  }
}

.hsSubNavigation {
  display: none;

  @include gammaSize {
    display: block;

    ul {
      list-style-type: none;
      flex-direction: column;
      padding: 37px 30px;
      background: $color-sky-blue-dark;
      position: absolute;
      width: 95%;
      z-index: 12;
      margin: 0;
      display: flex;
      align-items: center;
      padding: 15px;

      .show {
        opacity: 0;
      }

      li {
        width: 100%;
        color: $white;
        border-bottom: none;
        cursor: pointer;
        transition: all 0.5s;

        a {
          display: inline-block;
          font-size: 20px;
          font-weight: 600;
          text-align: left;
        }

        a {
          padding: 14px 0;
        }

        &.active {
          background: transparent;
          border-bottom: none;

          a {
            position: relative;

            &:after {
              content: '';
              width: 100%;
              height: 4px;
              background: $color-sun-yellow-medium;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }
        }
      }
    }
  }
}

.skeletonActionCard {
  border-radius: 20px !important;
  height: 180px !important;
  margin-top: 60px !important;
  margin-bottom: -30px !important;

  @include gammaSize {
    height: 400px !important;

    margin-top: 20px !important;
    margin-bottom: 0 !important;
  }
}
