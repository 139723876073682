@import '../../../../assets/styles/_variables.scss';
@import '../../../../assets/styles/_mixns.scss';

.topIconImage {
  width: 50px;
}

.iconCircle {
  width: 96px;
  height: 96px;
  background-color: #e3f2fd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 24px;
  color: #1976d2;
}

.navigationButtons {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: auto;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
  margin-bottom: 10px;

  .backBtn {
    background: transparent;
    border: none;
    padding: 6px;
    border-radius: 8px;
    color: $text-dark-grey;
    width: 50px;

    &:hover {
      background: $color-gray-silver-light;
    }
  }
}
