@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.title {
  animation: fadeIn 0.5s ease-out;
  h1 {
    font-weight: 600;
    color: $text-dark-grey;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.searchContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  margin-bottom: 40px;
  margin-top: 15px;

  h6 {
    margin-top: 40px;
    font-weight: 600;
    text-align: center;
    color: $text-dark-grey;
    margin-bottom: 100px;
  }
}
