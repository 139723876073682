@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

.textStyles {
  h1 {
    font-size: 2rem;
    font-weight: 600;
    color: $text-dark-grey;

    @media (max-width: 1200px) {
      font-size: 1.75rem;
    }

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }

    @media (max-width: 480px) {
      font-size: 1.25rem;
    }
  }

  h2 {
    font-size: 1.75rem;
    font-weight: 600;
    color: $text-dark-grey;

    @media (max-width: 1200px) {
      font-size: 1.6rem;
    }

    @media (max-width: 768px) {
      font-size: 1.4rem;
    }

    @media (max-width: 480px) {
      font-size: 1.2rem;
    }
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: $text-dark-grey;

    @media (max-width: 1200px) {
      font-size: 1.4rem;
    }

    @media (max-width: 768px) {
      font-size: 1.25rem;
    }

    @media (max-width: 480px) {
      font-size: 1.1rem;
    }
  }

  h4 {
    font-size: 1.25rem;
    font-weight: 600;
    color: $text-dark-grey;

    @media (max-width: 1200px) {
      font-size: 1.15rem;
    }

    @media (max-width: 768px) {
      font-size: 1.05rem;
    }

    @media (max-width: 480px) {
      font-size: 1rem;
    }
  }

  h5 {
    font-size: 1.1rem;
    font-weight: 600;
    color: $text-dark-grey;

    @media (max-width: 1200px) {
      font-size: 1.05rem;
    }

    @media (max-width: 768px) {
      font-size: 0.95rem;
    }

    @media (max-width: 480px) {
      font-size: 0.85rem;
    }
  }

  h6 {
    font-size: 1rem;
    font-weight: 600;
    color: $text-dark-grey;

    @media (max-width: 1200px) {
      font-size: 0.95rem;
    }

    @media (max-width: 768px) {
      font-size: 0.85rem;
    }

    @media (max-width: 480px) {
      font-size: 0.75rem;
    }
  }

  p,
  span {
    font-size: 1rem;
    color: $text-dark-grey;

    @media (max-width: 1200px) {
      font-size: 0.95rem;
    }

    @media (max-width: 768px) {
      font-size: 0.875rem;
    }

    @media (max-width: 480px) {
      font-size: 0.75rem;
    }
  }

  b {
    font-weight: bold;
    color: $text-dark-grey;

    @media (max-width: 768px) {
      font-weight: 600;
    }
  }

  div {
    font-size: 1rem;
    color: $text-dark-grey;

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  }

  typography {
    font-size: 1rem;
    color: $text-dark-grey;

    @media (max-width: 768px) {
      font-size: 0.875rem;
    }
  }

  text {
    font-size: 1rem;
    color: $text-dark-grey;

    @media (max-width: 768px) {
      font-size: 0.875rem;
    }
  }
}
