@import '../../../../assets/styles/variables';

.hsSubNavigation {
  ul {
    list-style-type: none;
    margin: 0;
    display: flex;
    align-items: center;
    padding: 0 70px;
    background: $color-putty-medium;

    li {
      width: calc(100% / 5);
      border-bottom: 2px solid transparent;
      cursor: pointer;
      transition: all 0.5s;

      &.active {
        background: $color-putty-light;
        border-bottom: 2px solid $color-sun-yellow-medium;
      }

      a {
        display: block;
        text-align: center;
        padding: 14px 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .hsSubNavigation {
    ul {
      flex-direction: column;
      padding: 37px 30px;
      background: $color-sky-blue-dark;
      position: absolute;
      width: 100%;
      z-index: 12;

      @media screen and (max-width: 768px) {
        padding: 15px;
      }

      .show {
        opacity: 0;
      }

      li {
        width: 100%;
        color: $white;
        border-bottom: none;

        a {
          display: inline-block;
          font-size: 20px;
          font-weight: 600;
          text-align: left;
        }

        &.active {
          background: transparent;
          border-bottom: none;

          a {
            position: relative;

            &:after {
              content: '';
              width: 100%;
              height: 4px;
              background: $color-sun-yellow-medium;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }
        }
      }
    }
  }
}
