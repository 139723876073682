@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

.mainContainer {
  padding: 49px 68px 40px;
  min-height: calc(100vh - 266.8px);

  @media screen and (max-width: 768px) {
    padding: 20px;
  }

  .pageTitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .pageTitle {
      font-weight: 600;
      color: $text-dark-grey;
      margin-bottom: 40px;
    }
  }
}

.filterRoot {
  background-color: $color-sky-blue-dark;
  padding: 23px 30px;
  border-radius: 30px;
  margin-bottom: 30px;

  .sliceAndDiceSection {
    .formTextInputStyles {
      label {
        color: white;
        margin-bottom: 10px;
      }

      .tooltipContainer {
        margin-bottom: 5px;
        color: $text-dark-grey;
      }
    }

    .sliceAndDiceFlex {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      margin-bottom: 30px;

      > div {
        flex: 1;
        min-width: calc(15% - 20px);
        max-width: calc(15% - 20px);

        @include mediaBetween(1520px, 900px) {
          min-width: calc(20% - 20px);
          max-width: calc(20% - 20px);
        }

        @include gammaSize {
          min-width: calc(30% - 5px);
          max-width: calc(30% - 5px);
        }

        @include alphaSize {
          min-width: 100%;
          max-width: 100%;
        }
      }
    }

    input {
      width: 100%;
      border: 3px solid $color-sky-blue-medium;
      border-radius: 55px;
      height: 40px;
      padding: 7.5px 15px;
      font-weight: 600;
      font-family: 'Titillium Web', sans-serif;
      margin-top: 20px;
      color: $color-sky-blue-medium;

      &:focus {
        border: 2px solid $color-sky-blue-light;
      }

      &:focus-visible {
        outline: 0;
      }

      &:hover {
        border: 3px solid $color-sky-blue-light;
      }

      &:active {
        border: 3px solid $color-sky-blue-light;
        box-shadow: none;
      }

      &.error {
        border: 3px solid $red;
      }
    }
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.buttonGroup {
  display: flex;
  gap: 15px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.customDatePicker {
  margin-top: 100px;
}

.tooltipContainer {
  display: flex;
  flex-direction: column;

  p {
    color: $white;
    margin-bottom: -5px;
    font-weight: 600;
    font-family: 'Titillium Web', sans-serif;

    @include gammaSize {
      margin-bottom: -25px;
    }
  }
}
