@import '../../../assets/styles/variables';

.tableRoot {
  max-width: 100%;
  width: 100%;
  max-height: 800px;
  position: relative;

  thead {
    height: 49px;
    background-color: #eee;

    tr {
      th {
        padding: 14px;
        background-color: #eee;
        border: 1px solid #dee2e6;
      }
    }

    .tableBodyRow {
      padding: 0.5rem;
      box-shadow: inset 0 0 0 9999px;
    }
  }

  .headerLabelCell {
    font-weight: 600;
  }

  tbody {
    tr {
      height: 49px;

      td {
        border: 1px solid #dee2e6;
        padding: 10px;
      }

      &:hover {
        background-color: #eee;
      }
    }
  }
}

.noDataSection {
  font-size: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  p {
    font-size: 24px;
    font-weight: 400;
    margin-top: 40px;
    color: $text-dark-grey;
    text-align: center;
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .MuiTablePagination-selectLabel,
  & .MuiTablePagination-displayedRows {
    align-self: center;
  }

  & .MuiInputBase-root {
    margin-top: 0 !important; // Ensure no extra margin on the dropdown
  }
}
