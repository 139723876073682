@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 263.69px);
  cursor: default;

  @include gammaSize {
    align-items: flex-start;
  }

  .globalRootCard {
    width: auto;
    margin: 5% auto;
    padding: 24px;
    background: $white;
    border-radius: 24px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    // TODO: CHECK HOW THIS COMMENTED ALIGN WILL AFFECT THE CARD
    // text-align: center;
    transition: opacity 0.3s ease-in-out;
    min-height: auto;
    display: flex;
    flex-direction: column;
    height: 100%;

    @include gammaSize {
      min-height: calc(100vh - 80.69px);
      width: 100%;
      margin: 0;
      margin-bottom: 40px;
      border-radius: 0px;
      box-shadow: none;
    }

    h1,
    h2 {
      font-size: 1.8rem;
      margin-bottom: 16px;
      color: #333;
    }

    p {
      font-size: 1rem;
      color: #555;
      margin-bottom: 20px;
    }

    button {
      display: block;
      width: 100%;
      padding: 12px;
      font-size: 1rem;
      cursor: pointer;
      border: none;
      border-radius: 8px;
      background: #007bff;
      color: white;
      transition: background 0.3s, opacity 0.3s;
      margin-top: auto;

      &:hover {
        background: #0056b3;
        opacity: 0.9;
      }

      &:active {
        opacity: 0.7;
      }
    }
  }
}
