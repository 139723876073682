@import '../../assets/styles/variables';

.hsActionsCard {
  display: flex;
  gap: 10px;
  background: #ffffff;
  border: 2px solid transparent;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  border-radius: 20px;
  min-height: 167px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  + {
    .hsActionsCard {
      margin-top: 17px;
    }
  }
  p {
    margin: 0;
  }

  &Title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    p {
      display: inline-flex;
      align-items: center;
      gap: 10px;
      color: $text-dark-grey;

      @media screen and (max-width: 768px) {
        margin-top: 15px;
      }

      span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 3px solid transparent;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }
    }

    p:first-of-type {
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
    }

    p:last-of-type {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
  }

  &Content {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: $text-dark-grey;
  }

  &Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      span {
        display: inline-flex;
        padding: 0 10px;
        border-radius: 4px;
        font-weight: 600;
        font-size: 22px;
        line-height: 20px;
        color: $white;
      }
    }

    &Checkbox {
      width: 100px;
    }
  }

  &.calmLilac {
    border-color: $color-calm-lilac-medium;

    .hsActionsCardTitle {
      span {
        border-color: $color-calm-lilac-medium;
      }
    }

    .hsActionsCardFooter {
      p {
        span {
          background: $color-calm-lilac-medium;
        }
      }
    }

    .lineBetween {
      background-color: $color-calm-lilac-medium;
    }
  }

  &.skyBlue {
    border-color: $color-sky-blue-medium;

    .hsActionsCardTitle {
      span {
        border-color: $color-sky-blue-medium;
      }
    }

    .hsActionsCardFooter {
      p {
        span {
          background: $color-sky-blue-medium;
        }
      }
    }
    .lineBetween {
      background-color: $color-sky-blue-medium;
    }
  }

  &.sunYellow {
    border-color: $color-sun-yellow-medium;

    .hsActionsCardTitle {
      span {
        border-color: $color-sun-yellow-medium;
      }
    }

    .hsActionsCardFooter {
      p {
        span {
          background: $color-sun-yellow-medium;
        }
      }
    }
    .lineBetween {
      background-color: $color-sun-yellow-medium;
    }
  }

  &.zingyGreen {
    border-color: $color-zingy-green-medium;

    .hsActionsCardTitle {
      span {
        border-color: $color-zingy-green-medium;
      }
    }

    .hsActionsCardFooter {
      p {
        span {
          background: $go-green;
        }
      }
    }
    .lineBetween {
      background-color: $color-zingy-green-medium;
    }
  }

  &.aquaGreen {
    border-color: $color-aqua-green-medium;

    .hsActionsCardTitle {
      span {
        border-color: $color-aqua-green-medium;
      }
    }

    .hsActionsCardFooter {
      p {
        span {
          background: $color-aqua-green-medium;
        }
      }
    }
    .lineBetween {
      background-color: $color-aqua-green-medium;
    }
  }

  &.warmOrange {
    border-color: $color-warm-orange-medium;

    .hsActionsCardTitle {
      span {
        border-color: $color-warm-orange-medium;
      }
    }

    .hsActionsCardFooter {
      p {
        span {
          background: $color-warm-orange-medium;
        }
      }
    }
  }
  .lineBetween {
    background-color: $color-warm-orange-medium;
  }
}

.inputContainer {
  width: 100%;
  display: flex;
  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }

  .hsActionsCardTitle {
    width: 100%;
  }

  .buttonContainer {
    width: 80%;
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: 768px) {
      padding: 20px 0;
    }
  }
}

.tabContent {
  width: 50%;
  padding: 20px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.lineBetween {
  height: 120px;
  width: 1px;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 1px;
  }
}

.viewMore {
  font-weight: 600;
  cursor: pointer;
  margin-left: 10px;
}

.dateContainer {
  .formattedDate {
    background-color: #828282 !important;
    border-radius: 4px !important;
    padding: 0 10px !important;
    margin: 0 !important;
    font-size: 14px !important;
    color: white !important;
    width: 100px !important;
    justify-content: center !important;
    display: flex !important;
  }
  .focusArea {
    font-size: 20px !important;
    font-weight: 600 !important;
    margin-top: 15px !important;
  }
}

.categorySection {
  p {
    font-weight: 400 !important;
  }
}

.truncateSection {
  font-family: 'Titillium Web' !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  word-break: break-all !important;
  -ms-word-break: break-all !important;
  margin-top: 10px !important;
  display: inline !important;
}
