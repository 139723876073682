@import '../../../../assets/styles/_variables.scss';
@import '../../../../assets/styles/_mixns.scss';

.answersContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  gap: 16px;
  margin-top: 15px;

  button {
    text-align: start;
    background-color: transparent;
    color: $text-dark-grey;
    border: 2px solid $color-gray-silver-medium;
    padding: 16px;
    border-radius: 12px;
    transition: transform 0.3s ease-in-out;

    span {
      background-color: $color-gray-silver-light;
      padding: 2px 10px 2px 10px;
      border-radius: 4px;
      font-weight: 600;
    }

    &:hover {
      background: $color-gray-silver-light;
      border: 2px solid $color-gray-silver-light;
      transform: translateY(0) scale(1.03);
    }
  }

  .activeAnswer {
    border: 2px solid $color-sky-blue-medium !important;
  }
}
