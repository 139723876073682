@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

$borderColors: $color-sky-blue-medium, $color-warm-orange-medium, $color-sun-yellow-medium,
  $color-calm-lilac-medium, $color-sky-blue-medium, $color-zingy-green-medium,
  $color-calm-lilac-medium, $color-warm-orange-medium, $color-sun-yellow-medium;

$totalColors: length($borderColors);

@for $i from 1 through 9999 {
  $index: ($i - 1) % $totalColors + 1;

  .borderedBox:nth-child(#{$i}) {
    border: 2px solid nth($borderColors, $index);
    border-radius: 24px;
    padding: 20px;
    margin: 60px 0px -20px 0px;
    background-color: $white;

    @include betaSize {
      margin: 10px 0px 30px 0px;
    }

    @include gammaSize {
      margin: 10px 0px 30px 0px;
    }
  }

  .borderBoxContent {
    @include flexContainer(column, nowrap, space-between, flex-start, 0);

    h2 {
      @include subHeading2;
      margin: 0 0 10px;
    }

    p {
      @include bodyCopy;
      margin: 0 0 15px;
      text-align: justify;
    }
  }

  .actions {
    @include flexContainer(none, wrap, space-between, normal, 20px);
    margin-top: 10px;

    @include betaSize {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex: 1;
      gap: 10px;
    }
  }
}
