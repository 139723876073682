@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.zoomText {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
  color: $text-dark-grey;
  height: 100px;

  @include gammaSize {
    height: 150px;
  }
}
