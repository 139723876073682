@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixns';

.mainContainer {
  padding: 49px 68px 40px;
  min-height: calc(100vh - 266.8px);

  @media screen and (max-width: 768px) {
    padding: 20px;
  }

  .mainTitle {
    color: $text-dark-grey;
    font-weight: 600;
    font-family: 'Titillium Web', sans-serif;
  }

  .employerNotifyContainer {
    background-color: $white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 30px 10px 30px;
    align-items: center;
    border-radius: 50px;
    box-shadow: 4px 4px 4px rgb(0 0 0 / 5%);
    border: 1px solid $color-putty-medium;
    margin-top: 30px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      transform: translateY(-2px);
    }

    .leftSection {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .notificationBell {
        width: 35px;
        height: 35px;
      }
      p {
        margin-top: 16px;
        color: $error-red;
        font-weight: 500;
        font-size: 20px;
        margin-left: 10px;
      }
    }
  }
}

.navigationContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
  margin-top: 40px;

  @include gammaSize {
    flex-direction: column;
  }

  .navigationItem {
    background-color: $white;
    border-radius: 50px;
    box-shadow: 4px 4px 4px rgb(0 0 0 / 5%);
    flex: 1 1 calc(25% - 20px);
    min-width: 200px;
    aspect-ratio: 1.5 / 1;
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;

    @include gammaSize {
      flex: 1 1 100%;
      aspect-ratio: unset;
      padding: 40px;
    }

    @media (max-width: 1024px) {
      .navigationItem {
        flex: 1 1 calc(50% - 20px);
      }
    }

    &:hover {
      transform: translateY(-10px);
    }

    .navigationBtn {
      margin-top: 40px;
      margin-bottom: -20px;
    }
  }

  .navigationItem1 {
    border: 3px solid $color-calm-lilac-medium;
  }

  .navigationItem2 {
    border: 3px solid $color-warm-orange-medium;
  }

  .navigationItem3 {
    border: 3px solid $color-aqua-green-medium;
  }

  .navigationItem4 {
    border: 3px solid $color-sun-yellow-medium;
  }

  .navigationIcon {
    width: 70px;
    height: 70px;
  }

  h4 {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
  }
}

.employerNotifyLoadingContainer {
  height: 80px !important;
  border-radius: 50px !important;
  margin-top: 30px !important;
}
