@import '../../../../../../assets/styles/variables';

.hmgIcn {
  width: 36px;
  cursor: pointer;

  &.hmgIcnDark {
    &:before,
    &:after,
    div {
      background: $white;
    }
  }

  &:before,
  &:after,
  div {
    background: $color-sky-blue-medium;
    content: '';
    display: block;
    height: 5px;
    border-radius: 4px;
    margin: 6px 0;
    transition: 0.5s;
  }

  &:before {
    margin-top: 0;
  }

  &:after {
    margin-bottom: 0;
  }

  &.opened {
    margin-top: 3px;

    &:before {
      transform: translateY(8px) rotate(135deg);
    }

    &:after {
      transform: translateY(-14px) rotate(-135deg);
    }

    div {
      transform: scale(0);
    }
  }
}
