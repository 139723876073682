@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixns';

.searchInput {
  margin-top: 10px;

  @include gammaSize {
    margin-top: 4px;
  }
}

.learningSearchList {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;

  .autocompleteImageOption {
    width: 100px;
    height: 60px;
    object-fit: cover;
    background-color: $color-putty-medium;
    flex-shrink: 0;
    border-radius: 8px;
    border: 2px solid $white;

    @include gammaSize {
      width: 60px;
      height: 40px;
    }
  }

  .contentAutocompleteOption {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;

    span {
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 5px;
    }

    h6 {
      margin: 0;
      font-weight: 600;
      color: $text-dark-grey;
      text-align: left;
      font-size: 16px;
      margin-left: 15px;

      @include gammaSize {
        font-size: 12px;
        font-weight: 400;
        margin-left: 5px;
      }
    }
  }
}

.learningSearchList:hover {
  background-color: $color-putty-medium;
}
